<template>
  <div class="lg:py-6 mb-6 space-y-2 md:space-y-0">
    <div class="lg:flex items-center justify-between mb-3">
      <div class="lg:w-4/12 md:shadow-xl">
        <!--        <label for="search" class="block text-sm font-medium text-gray-700">{{ loc.search }}</label>-->
        <div class="mt-1 relative flex items-center">
          <div class="absolute inset-y-0 left-0 flex py-2 pl-1.5">
            <search-icon class="text-gray-500 h-5 w-5"/>
          </div>
          <div class="w-full md:shadow-sm focus:ring-base-500 focus:border-base-500 block w-full sm:text-sm border-gray-100 rounded-md">
            <input v-model.lazy="search" v-debounce="400" type="text" name="search" id="search" :placeholder="loc.search_by_benefit_title"
                   class="w-full md:shadow-sm focus:ring-base-500 focus:border-base-500 block w-full pr-12 sm:text-sm border-gray-200 rounded-md pl-9"/>
          </div>
        </div>
      </div>
      <div :class="`bg-white p-1 rounded-xl lg:w-6/12 xl:w-5/12 lg:mx-0 mt-2.5 lg:mt-0 hidden md:block`">
        <div v-if="benefits_type !== 'special'" :class="`relative mx-auto lg:mx-0 mt-2.5 lg:mt-0 flex items-center justify-between font-medium bg-white rounded-xl w-full cursor-pointer select-none overflow-hidden`">
          <div :class="`absolute left-0 top-0 transform ${type === '' ? 'translate-x-0' : ''} ${type === 'in_store' ? 'translate-x-100%' : ''} ${type === 'invoice' ? 'translate-x-200%' : ''} ${type === 'web' ? 'translate-x-300%' : ''} ${type === 'voucher' ? 'translate-x-400%' : ''} top-0 bg-base-600 w-1/5 h-8 p-1 rounded-xl animation duration-200 ease-in-out`"></div>
          <div @click="select_type('')" :class="`${type === '' ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
            <p class="relative">All</p>
          </div>
          <div @click="select_type('in_store')" :class="`${type === 'in_store' ? 'text-white' : ''} w-1/5  flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
            <p class="relative">In-Store</p>
          </div>
          <div @click="select_type('invoice')" :class="`${type === 'invoice' ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
            <p class="relative">Invoice</p>
          </div>
          <div @click="select_type('web')" :class="`${type === 'web' ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
            <p class="relative">Web</p>
          </div>
          <div @click="select_type('voucher')" :class="`${type === 'voucher' ? 'text-white' : ''} w-1/5 flex items-center justify-center rounded-lg h-8 animation duration-300 ease-in-out`">
            <p class="relative">Voucher</p>
          </div>
        </div>
      </div>
      <div v-if="benefits_type !== 'special'" class="lg:w-5/12 md:h-10 mt-2 lg:mt-0 space-y-2 md:space-y-0 grid md:hidden grid-cols-2 md:grid-cols-4 items-center gap-1 lg:gap-x-1.5 xl:gap-x-3 overflow-hidden">
        <button @click="select_type('')" :class="`col-span-2 md:col-span-1 h-10 text-sm rounded-md border ${type === '' ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">ALL</button>
        <button @click="select_type('in_store')" :class="`h-10 text-sm rounded-md  border ${type === 'in_store' ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">IN-STORE</button>
        <button @click="select_type('invoice')" :class="`h-10 text-sm rounded-md  border ${type === 'invoice' ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">INVOICE</button>
        <button @click="select_type('web')" :class="`h-10 text-sm rounded-md border ${type === 'web' ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">WEB</button>
        <button @click="select_type('voucher')" :class="`h-10 text-sm rounded-md border ${type === 'voucher' ? 'bg-base-600 text-white' : 'hover:border hover:border-base-500 hover:bg-gray-50 text-gray-400'} animation duration-200 ease-in-out`">VOUCHER</button>
      </div>
    </div>
    <div class="lg:flex items-center justify-between space-y-2 md:space-y-0">
      <div class="lg:w-6/12 md:flex items-center justify-start gap-x-2 space-y-2 md:space-y-0">
        <div class="md:w-4/12 shadow-xl">

          <Menu as="div" class="relative w-full">
            <!--            <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.category }}</label>-->
            <MenuButton :class="`focus:ring-2 ring-base-500 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2 ${$refs['categories']?.visible ? 'ring-2 ring-base-500' : ''}`">
              <p class="w-5/6 text-left truncate text-sm" :class="selected_category?.name ? 'text-black' : ''">{{ selected_category ? parse_name(selected_category?.name) : loc.category }}</p>
              <!--              <p v-if="$refs['categories']?.visible">test</p>-->
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['categories']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
              <!--              <font-awesome-icon v-else icon="fa-chevron-up" />-->
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="categories" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-72 overflow-y-scroll">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="category in categories" @click="setCategory(category.id)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="selected_category?.id === category.id ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(category.name) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div v-if="showSubcategory && selected_category.children.length" class="md:w-4/12 shadow-xl">
          <Menu as="div" class="relative w-full">
            <!--            <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.category }}</label>-->
            <MenuButton :class="`focus:ring-2 ring-base-500 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2 ${$refs['subcategories']?.visible ? 'ring-2 ring-base-500' : ''}`">
              <p class="w-5/6 truncate text-left text-sm" :class="selectedSubcategory?.name ? 'text-black' : ''">{{ selectedSubcategory?.name ? parse_name(selectedSubcategory?.name) : loc.subcategory }}</p>
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['subcategories']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="subcategories" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-72 overflow-y-auto">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="subCat in selected_category.children" @click="setSubcategory(subCat.id)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer hover:bg-gray-200 hover:text-base-600" :class="subcategory && subcategory === subCat.id ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(subCat.name) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="md:w-4/12 lg:pr-2">
          <!--          <label class="invisible">placeholder</label>-->
          <div v-if="get_selected_providers.length === 0" @click="category_providers=true" class="flex shadow-xl justify-center items-center bg-base-600 w-full h-10 rounded-md text-white text-center border hover:bg-base-500 transition duration-200 ease-in-out border-gray-300 cursor-pointer">{{loc.providers}} <arrows-expand-icon class="ml-2 w-5 h-5"/></div>
          <div v-if="get_selected_providers.length > 0" @click="resetProviders" class="flex shadow-xl justify-center items-center bg-red-600 w-full h-10 rounded-md text-white text-center border hover:bg-red-500 transition duration-200 ease-in-out border-gray-300 cursor-pointer">{{loc.reset_providers}}</div>
        </div>
      </div>
      <div class="lg:w-6/12 xl:w-5/12 md:flex items-center lg:justify-end gap-x-2 space-y-2 md:space-y-0">
        <div class="md:w-5/12 xl:w-4/12 shadow-xl">
          <Menu ref="loc" as="div" class="relative w-full">
            <!--            <label for="location" class="block text-sm font-medium text-gray-700">{{ loc.location }}</label>-->
            <MenuButton :class="`focus:ring-2 ring-base-500 mt-2 lg:mt-0 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between gap-x-1 text-gray-500 relative px-2 ${$refs['location']?.visible ? 'ring-2 ring-base-500' : ''}`">
              <p class="w-5/6 text-left truncate text-sm" :class="locationName ? 'text-black' : ''">{{ locationName ? parse_name(locationName) : loc.location }}</p>
<!--              <font-awesome-icon v-if="locationName" @click="setLocation('', $event)"  icon="far-xmark-circle" class="relative text-red-600 mr-0.5" :class="$refs['location']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />-->
              <font-awesome-icon v-if="locationName" :icon="['far', 'xmark-circle']" @click="setLocation('', $event)" class="relative text-red-600 mr-0.5" />
              <font-awesome-icon  icon="fa-chevron-down" class="hover:text-base-600" :class="$refs['location']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="location" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-y-auto">
                <div v-if="isSerbia" class="py-1">
                  <MenuItem v-slot="{ active }" v-for="location in locationOptionsSrb" @click="setLocation(location)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer capitalize hover:bg-gray-200 hover:text-base-600" :class="location.name === locationName ? 'bg-base-600 text-white font-medium': ''">
                      {{ location.name }}
                    </div>
                  </MenuItem>
                </div>
                <div v-if="isMontenegro" class="py-1">
                  <MenuItem v-slot="{ active }" v-for="location in locationOptionsMne" @click="setLocation(location)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer capitalize hover:bg-gray-200 hover:text-base-600" :class="location.name === locationName ? 'bg-base-600 text-white font-medium': ''">
                      {{ location.name }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
        <div class="md:w-4/12 md:shadow-xl flex items-center gap-x-2 md:block">
          <Menu as="div" class="relative w-1/2 md:w-full">
            <!--            <label class="block text-sm font-medium text-gray-700">{{ loc.sort_by }}</label>-->
            <MenuButton :class="`focus:ring-2 ring-base-500 mt-2 lg:mt-0 rounded-md shadow border w-full h-10 bg-white flex items-center justify-between text-gray-500 hover:text-base-600 relative px-2 ${$refs['sorting']?.visible ? 'ring-2 ring-base-500' : ''}`">
              <p class="w-5/6 text-left truncate text-sm" :class="sorting ? 'text-black' : ''">{{ sorting ? parse_name(sortTranslates[sorting]) : loc.sort_by }}</p>
              <!--              <p class="w-5/6 text-left truncate text-sm" :class="sorting ? 'text-black' : ''">{{ sorting }}</p>-->
              <font-awesome-icon v-if="sorting" :icon="['far', 'xmark-circle']" @click="setSorting($event, '')" class="relative z-30 text-red-600 mr-1.5" />
<!--              <font-awesome-icon v-if="sorting" @click="setSorting($event, '')"  icon="fa-times" class="relative z-30 hover:text-red-600 mr-1.5" :class="$refs['location']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />-->
              <font-awesome-icon  icon="fa-chevron-down" :class="$refs['sorting']?.visible ? 'transform -rotate-180 transition duration-200 linear' : 'transition duration-200 linear'" />
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems ref="sorting" class="origin-top-right absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10 h-40 overflow-y-auto">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-for="sort in sortOptions" @click="setSorting($event, sort)">
                    <div class="flex px-2 py-2 border-b text-sm cursor-pointer capitalize hover:bg-gray-200 hover:text-base-600" :class="sort === sorting ? 'bg-base-600 text-white font-medium': ''">
                      {{ parse_name(sortTranslates[sort]) }}
                    </div>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
          <div class="md:hidden relative w-1/2 z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
                @click="set_ordering('+')"
                type="button"
                :class="`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${ordering === '+' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
              <font-awesome-icon  icon="fa-up-long" :class="ordering === '+' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'" />
            </button>
            <button
                @click="set_ordering('-')"
                type="button"
                :class="`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
              <font-awesome-icon  icon="fa-down-long" :class="ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'" />
            </button>
          </div>
        </div>
        <div class="hidden md:flex items-center justify-center gap-x-2">
          <div class="relative w-full z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
                @click="set_ordering('+')"
                type="button"
                :class="`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${ordering === '+' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
              <font-awesome-icon  icon="fa-up-long" :class="ordering === '+' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'" />
            </button>
            <button
                @click="set_ordering('-')"
                type="button"
                :class="`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
              <font-awesome-icon  icon="fa-down-long" :class="ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'" />
            </button>
          </div>
          <div class="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
                @click="switch_view('grid')"
                type="button"
                :class="`h-10 w-12 md:w-9 relative inline-flex items-center p-1 rounded-l-md border border-gray-300 ${benefit_preview_type == 'grid' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
<!--              <ViewGridIcon class="h-4 w-4 mx-auto" aria-hidden="true"/>-->
              <font-awesome-icon  icon="fa-grip" class="mx-auto" />
            </button>
            <button
                @click="switch_view('list')"
                type="button"
                :class="`h-10 w-12 md:w-9 relative inline-flex items-center p-1 rounded-r-md border border-gray-300 ${benefit_preview_type == 'list' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500`">
<!--              <MenuIcon class="h-4 w-4 mx-auto" aria-hidden="true"/>-->
              <font-awesome-icon  icon="fa-list-ul" class="mx-auto" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal class="transform border border-red-500" :open="category_providers" :submit-text="loc.load" :cancel-text="loc.cancel" @close="category_providers = false; toggle_selected_providers('reset')" @submit="fetchBenefits(); category_providers = false;" :loading="false">
      <providers-select />
    </modal>

  </div>
</template>

<script>

import {SearchIcon, ChevronUpIcon, ChevronDownIcon, MenuIcon, ViewGridIcon, ArrowsExpandIcon} from "@heroicons/vue/solid"
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import modal from "@/components/ui/modal";
import providersSelect from "@/components/providersSelect";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'

export default {
  name: "additionalFilters",
  data() {
    return {
      category_providers: false,
      isSelectOpen: false,
      locationName: '',
      sortOptions: ['bought', 'added', 'name', 'price'],
      sortTranslates: {
        bought: 'Popularity / Popularnosti',
        added: 'Date / Datumu',
        name: 'Name / Imenu',
        price: 'Price / Ceni',
      },
      locationOptionsSrb: [
        { name: 'Cela Srbija', value: 'srbija' },
        { name: 'Beograd', value: 'beograd' },
        { name: 'Novi Sad', value: 'novi sad' },
        { name: 'Niš', value: 'niš' },
        { name: 'Kragujevac', value: 'kragujevac' },
        { name: 'Ostalo', value: 'ostalo' },
        { name: 'Online', value: 'online' },
      ],
      locationOptionsMne: [
        { name: 'Cela Crna Gora', value: 'crna gora' },
        { name: 'Podgorica', value: 'podgorica' },
        { name: 'Budva', value: 'budva' },
        { name: 'Nikšić', value: 'nikšić' },
        { name: 'Ostalo', value: 'ostalo' },
      ],
    }
  },
  components: {
    SearchIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    modal,
    providersSelect,
    ViewGridIcon,
    MenuIcon,
    ArrowsExpandIcon,

    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
  },
  computed: {
    ...mapGetters('benefits', ['benefits_type', 'get_selected_providers']),
    ...mapState('filters', ['categories', 'sort', 'search', 'ordering', 'type']),
    ...mapGetters('filters', ['current_category_object', 'selected_category', 'current_category_id', "search_string", "location"]),
    ...mapState('ui', ['benefit_preview_type']),
    isMontenegro() {
      return this.$store.state.auth.user.currency === 'EUR';
    },
    isSerbia() {
      return this.$store.state.auth.user.currency === 'RSD';
    },
    showSubcategory() {
      // show subcategory only when category selected
      return !!this.selected_category && !!this.current_category_id;
    },
    selectedSubcategory() {
      if (this.selected_category) {
        return this.selected_category.children.find((sub) => sub.id === this.subcategory);
      }
      return null;
    },
    selectedLocation() {
      if (this.location) return this.location;
      return null;
    },
    category: {
      get() {
        return this.selected_category ? this.selected_category.id : null
      },
      set(value) {
        this.select_category(value)
      }
    },
    subcategory: {
      get() {
        return this.current_category_id
      },
      set(value) {
        this.select_category(value)
      }
    },
    sorting: {
      get() {
        if (this.sort && this.sort !== '') return this.sort;
        return null;
      },
      set(value) {
        this.select_sort(value)
      }
    },
    search: {
      get() {
        return this.search_string
      },
      set(value) {
        this.set_search(value)
      }
    },
    location: {
      get() {
        return this.location
      },
      set(value) {
        this.set_location(value)
      }
    },
  },
  mounted() {
    console.log(this.type);
  },
  methods: {
    ...mapMutations('benefits', ['toggle_selected_providers']),
    ...mapActions('filters', ['select_category', 'select_sort', 'set_search', 'set_ordering', 'set_location', 'select_type']),
    ...mapActions('benefits', ['fetchBenefits']),
    ...mapMutations('ui', {
      switch_view: "switch_benefit_preview_type"
    }),
    async resetProviders() {
      await this.toggle_selected_providers('reset');
      await this.fetchBenefits();
    },
    toggleSelect() {
      this.isSelectOpen = !this.isSelectOpen;
    },
    setCategory(id) {
      if (this.category === id) return;
      this.category = id;
    },
    setSubcategory(id) {
      if (this.subcategory === id) return;
      this.category = this.current_category_id;
      this.subcategory = id;
    },
    setSorting(e, sortBy) {
      if (sortBy === "") e.preventDefault();
      if (this.sorting === sortBy) return;
      this.sorting = sortBy;
    },
    setLocation(location, e) {
      if (location === "") e.preventDefault();
      if (this.locationName === location.name) return;
      this.locationName = location.name;
      this.set_location(location.value);
    },
    parse_name(name) {
      let srb = '';
      let eng = '';
      if (name.includes('/')) {
        eng = name.split('/')[0];
        srb = name.split('/')[1];
        if (this.$store.state.auth.user.language == "SR") {
          return srb;
        } else {
          return eng;
        }
      }
      return name;
    },
    // ...mapMutations('filters', ['set_sort'])
  }
}
</script>

<style scoped>
.opened {
  /* Dodatna stilizacija za otvoreni meni, ako je potrebna */
  border: 1px saddlebrown solid;
}

</style>
